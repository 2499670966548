import React, { useMemo } from "react";
import { WidgetLabelPosition } from "legacy/constants/WidgetConstants";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { useAppSelector } from "store/helpers";

import { KeyValuePropertyKey } from "./components/KeyValuePropertyKey";
import { KeyValuePropertyValue } from "./components/KeyValuePropertyValue";
import {
  createCssVariables,
  KeyValueComponentClass,
  KeyValueEntryClass,
  useInputStyles,
  useLabelStyles,
} from "./styles";
import { KeyValueProperty, KeyValueComponentProps } from "./types";

export const KeyValueComponent = (props: KeyValueComponentProps) => {
  const theme = useAppSelector(selectGeneratedTheme);

  const cssVariableDeclarations = useMemo(
    () =>
      createCssVariables({
        theme,
        styleProps: props.styleProps,
      }),
    [theme, props.styleProps],
  );

  const keyStyles = useLabelStyles(props.keyProps);
  const valueStyles = useInputStyles(props.valueProps);
  const isVertical = props.keyProps?.position === WidgetLabelPosition.TOP;

  return (
    <div style={cssVariableDeclarations} className={KeyValueComponentClass}>
      {props.propertiesOrder.map((key) => {
        const property: KeyValueProperty = props.properties[key];

        if (!property.isVisible) {
          return null;
        }

        return (
          <div
            key={key}
            className={`${KeyValueEntryClass} ${isVertical ? "vertical" : ""}`}
          >
            <KeyValuePropertyKey
              style={keyStyles.style}
              property={property}
              className={CLASS_NAMES.KEY_VALUE_ENTRY_KEY}
            />
            <KeyValuePropertyValue
              style={valueStyles.style}
              property={property}
              data={props.keyValueData}
              runEventHandlers={props.runEventHandlers}
              className={CLASS_NAMES.KEY_VALUE_ENTRY_VALUE}
            />
          </div>
        );
      })}
    </div>
  );
};
