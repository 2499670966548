import { createSelector } from "@reduxjs/toolkit";
import equal from "@superblocksteam/fast-deep-equal/es6";
import { TriggerStepType, WidgetTypes } from "@superblocksteam/shared";
import { isPlainObject } from "lodash";
import { ItemKinds } from "legacy/pages/Editor/PropertyPane/ItemKindConstants";
import { getWidget } from "legacy/selectors/sagaSelectors";
import { WidgetProps } from "legacy/widgets";
import { ColumnProperties } from "legacy/widgets/TableWidget/TableComponent/Constants";
import { TableWidgetProps } from "legacy/widgets/TableWidget/TableWidgetConstants";
import { getColumnIdFromAi } from "store/slices/ai/columnUtils";
import type { AppState } from "store/types";

interface AiAction {
  action: string;
  column: string;
  [key: string]: any;
}

interface ColumnChange {
  columnId: string;
  action: AiAction;
  column: ColumnProperties;
}

export const selectAiState = (state: AppState) => state.ai;

export const selectAiModalOpen = createSelector(
  selectAiState,
  (state) => state.selectedWidgetId !== undefined,
);

export const selectInitialPosition = createSelector(
  selectAiState,
  (state) => state.initialPosition,
);

export const selectAiEditedWidgetId = createSelector(
  selectAiState,
  (state) => state.selectedWidgetId,
);

export const selectAiDataTreeChangesById = createSelector(
  selectAiState,
  (state) => {
    if (!state.selectedWidgetId || !state.dataTreeChanges) {
      return undefined;
    }
    return {
      ...state.dependentChangesByWidgetId,
      [state.selectedWidgetId]: state.dataTreeChanges,
    } as Record<string, Partial<Omit<WidgetProps, "children">>>;
  },
);

export const selectAiComponentDataTreeEdits = createSelector(
  selectAiState,
  (state) => {
    return state.dataTreeChanges;
  },
);

export const selectAiComponentPropertiesToChange = createSelector(
  selectAiState,
  (state) => {
    return state.propertiesToChange;
  },
);

export const selectAddedRemovedTableColumns = createSelector(
  [
    selectAiState,
    (state: AppState) => state,
    (_state: AppState, itemKind?: ItemKinds) => itemKind,
  ],
  (
    aiState,
    state,
    itemKind,
  ): { addedColumns: ColumnChange[]; removedColumns: ColumnChange[] } => {
    const emptyResult = {
      addedColumns: [],
      removedColumns: [],
    };
    if (!itemKind || itemKind !== ItemKinds.AI_EDITS) {
      return emptyResult;
    }

    if (!aiState.selectedWidgetId) {
      return emptyResult;
    }

    const selectedWidget = getWidget(state, aiState.selectedWidgetId);
    if (
      !selectedWidget ||
      selectedWidget.type !== WidgetTypes.TABLE_WIDGET ||
      !aiState.actions?.length
    ) {
      return emptyResult;
    }

    const tableWidget = selectedWidget as TableWidgetProps;
    const existingColumns = Object.keys(tableWidget.primaryColumns);

    const setActions = (aiState.actions || []).filter(
      (action: AiAction) => action?.action === "set" && action?.column != null,
    );

    // Added columns with their actions
    const addedColumns = setActions
      .filter((action) => !existingColumns.includes(action.column))
      .map((action) => {
        const primaryColumns = aiState.dataTreeChanges?.primaryColumns as
          | Record<string, ColumnProperties>
          | undefined;
        const column = primaryColumns?.[action.column];
        if (!column) return undefined;
        return {
          columnId: action.column,
          action,
          column,
        };
      })
      .filter((item): item is ColumnChange => item !== undefined);

    const removeActions = (aiState.actions || []).filter(
      (action: AiAction) =>
        action?.action === "remove" && action.column != null,
    );

    // Removed columns with their actions
    const removedColumns = removeActions
      .map((action) => {
        const columnId = getColumnIdFromAi(
          action.column,
          tableWidget.primaryColumns,
        );
        if (!columnId || !tableWidget.primaryColumns?.[columnId]) {
          return undefined;
        }
        // Skip derived columns as they just get hidden and not deleted
        if (!tableWidget.primaryColumns[columnId].isDerived) {
          return undefined;
        }
        const column = tableWidget.primaryColumns[columnId];
        return {
          columnId,
          action,
          column,
        };
      })
      .filter((item): item is ColumnChange => item !== undefined);

    return {
      addedColumns,
      removedColumns,
    };
  },
);

export const selectAddedRemovedEventTriggers = createSelector(
  [
    selectAiState,
    (state: AppState) => state,
    (_state: AppState, itemKind?: ItemKinds) => itemKind,
    (_state: AppState, _itemKind?: ItemKinds, propertyName?: string) =>
      propertyName,
  ],
  (
    aiState,
    state,
    itemKind,
    propertyName,
  ): { addedTriggers: any[]; removedTriggers: any[] } => {
    const emptyResult = {
      addedTriggers: [],
      removedTriggers: [],
    };
    if (!itemKind || itemKind !== ItemKinds.AI_EDITS || !propertyName) {
      return emptyResult;
    }

    if (!aiState.selectedWidgetId) {
      return emptyResult;
    }

    const selectedWidget = getWidget(state, aiState.selectedWidgetId);

    if (
      !selectedWidget ||
      !aiState.actions ||
      !aiState.actions?.length ||
      !aiState.dataTreeChanges
    ) {
      return emptyResult;
    }

    const addedEventTriggers = aiState.actions
      .filter((action) => {
        const isAddTriggerAction =
          action.action === "add" &&
          action.property === propertyName &&
          action.value &&
          isPlainObject(action.value) &&
          Object.values(TriggerStepType).includes(action.value.type);

        return isAddTriggerAction;
      })
      .map((addTriggerAction) => {
        return ((aiState.dataTreeChanges?.[propertyName] as any[]) || []).find(
          (dataTreeTrigger: any) => {
            return (
              dataTreeTrigger.type === addTriggerAction.value.type &&
              equal(dataTreeTrigger.value, addTriggerAction.value.value)
            );
          },
        );
      })
      .filter((trigger) => Boolean(trigger));

    const removedEventTriggers = (aiState.actions || [])
      .filter((action) => {
        const isRemoveTriggerAction =
          action.action === "remove" &&
          action.property === propertyName &&
          action.value?.type &&
          action.value?.type === "remove";

        return isRemoveTriggerAction;
      })
      .map((removeTriggerAction) => {
        const removeId = removeTriggerAction.value?.id;
        const propertyValue = selectedWidget[propertyName as keyof WidgetProps];

        if (!removeId || !propertyValue || !Array.isArray(propertyValue)) {
          return undefined;
        }

        return propertyValue.find((trigger: any) => trigger?.id === removeId);
      })
      .filter((trigger) => Boolean(trigger));

    return {
      addedTriggers: addedEventTriggers,
      removedTriggers: removedEventTriggers,
    };
  },
);
