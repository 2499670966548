import { Dimension } from "@superblocksteam/shared";
import { get } from "lodash";
import { CurrencyList } from "legacy/constants/FormatConstants";
import {
  PropsPanelCategory,
  type PropertyPaneCategoryConfig,
} from "legacy/constants/PropertyControlConstants";
import {
  PropertyPopoverMaxHeight,
  WidgetLabelPosition,
} from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import { PanelCategory } from "legacy/pages/Editor/PropertyPane/propertyPaneCategoryUtils";
import { GeneratedTheme } from "legacy/themes";
import {
  TIMEZONE_OPTIONS,
  DATE_OUTPUT_FORMATS,
  DATE_INPUT_FORMATS,
  NUMBER_FORMATTING_OPTIONS,
} from "legacy/utils/FormatUtils";
import { createPerCornerBorderRadius } from "pages/Editors/AppBuilder/Sidebar/BorderRadiusEditor";
import { getFirstPathSegments, getParentPath } from "utils/dottedPaths";
import { BUTTON_STYLE_OPTIONS } from "../ButtonWidget/constants";
import { THEME_STYLE_DEFAULTS } from "../ButtonWidget/constants";
import { BooleanStyleFalse, ImageSize } from "../Shared/ValueWithTypeUtils";
import {
  iconPositionProperty,
  iconProperty,
  maximumFractionDigitsProperty,
  minimumFractionDigitsProperty,
} from "../appearanceProperties";
import {
  labelPositionProperty,
  paddingProperty,
  visibleProperties,
  sizeSection,
} from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import { styleProperties, typographyProperties } from "../styleProperties";
import {
  DEFAULT_BORDER_OBJECT,
  KEY_VALUE_WIDGET_PROPERTY_TYPES,
} from "./constants";
import { DEFAULT_KEY_VALUE_WIDGET_TEXT_STYLE_VARIANT } from "./styles";
import {
  KeyValueProperty,
  PropertyType,
  type KeyValueWidgetProps,
} from "./types";
import { getProperties } from "./utils";

const hideForTypes = (types: PropertyType[]) => {
  return (props: KeyValueWidgetProps, propertyPath: string) => {
    const type = get(
      props,
      `${getFirstPathSegments(propertyPath, 2)}.type`,
      "",
    );
    return types.includes(type);
  };
};

const showForTypes = (types: PropertyType[]) => {
  return (props: KeyValueWidgetProps, propertyPath: string) => {
    const type = get(
      props,
      `${getFirstPathSegments(propertyPath, 2)}.type`,
      "",
    );
    return !types.includes(type);
  };
};

const config: PropertyPaneCategoryConfig<KeyValueWidgetProps>[] = [
  PanelCategory(PropsPanelCategory.Content, [
    {
      helpText: "The object to be displayed",
      propertyName: "keyValueData",
      label: "Data",
      controlType: "INPUT_TEXT",
      placeholderText: 'Enter { "col1": "val1" }',
      inputType: "OBJECT",
      forceVertical: true,
      isBindProperty: true,
      isTriggerProperty: false,
      updateHook: ({
        props,
        propertyValue,
      }: {
        props: KeyValueWidgetProps;
        propertyValue: string;
      }) => {
        const currentProperties = props.properties;
        const parsedData = JSON.parse(propertyValue);
        const derivedProperties = Object.values(currentProperties).filter(
          (property) => property.isDerived,
        );

        return [
          {
            propertyPath: "properties",
            propertyValue: getProperties(parsedData, currentProperties),
          },
          {
            propertyPath: "propertiesOrder",
            propertyValue: [
              ...Object.keys(parsedData),
              ...Object.keys(derivedProperties),
            ],
          },
        ];
      },
    },
    {
      helpText: "Properties to display",
      propertyName: "properties",
      controlType: "KEY_VALUE_PROPERTIES",
      label: "Properties",
      isBindProperty: false,
      isTriggerProperty: false,
      headerControlType: "ADD_KEYVALUE_PROPERTY",
      panelConfig: {
        title: "Edit property",
        editableTitle: false,
        titlePropertyName: undefined,
        panelIdPropertyName: "id",
        showEditIcon: false,
        adaptiveHeight: { maxHeight: PropertyPopoverMaxHeight },
        children: [
          {
            sectionName: "Property Control",
            sectionCategory: PropsPanelCategory.Content,
            children: [
              {
                propertyName: "key",
                helpText: "The key of the key-value pair",
                label: "Key",
                controlType: "INPUT_TEXT",
                isBindProperty: true,
                isTriggerProperty: false,
                getDynamicProperties: (property: KeyValueProperty) => {
                  return {
                    disabled: !property.isDerived,
                  };
                },
              },
              {
                propertyName: "label",
                helpText: "The label displayed for the key-value pair",
                label: "Label",
                controlType: "INPUT_TEXT",
                isBindProperty: true,
                isTriggerProperty: false,
              },
              {
                propertyName: "type",
                helpText: "The data type for the value",
                label: "Data type",
                controlType: "DROP_DOWN",
                options: KEY_VALUE_WIDGET_PROPERTY_TYPES,
                isBindProperty: false,
                isTriggerProperty: false,
              },
              {
                propertyName: "computedValue",
                label: "Value",
                controlType: "INPUT_TEXT",
                hidden: (props: KeyValueWidgetProps, propertyPath: string) => {
                  const baseProperty = getParentPath(propertyPath);
                  const isDerived = get(
                    props,
                    `${baseProperty}.isDerived`,
                    false,
                  );
                  return !isDerived;
                },
                isBindProperty: true,
                isTriggerProperty: false,
              },
              {
                propertyName: "displayedValue",
                helpText: "Customize the value that’s displayed",
                label: "Displayed value",
                controlType: "INPUT_TEXT",
                isBindProperty: true,
                isTriggerProperty: false,
                visibility: "SHOW_NAME",
                isRemovable: true,
                defaultValue: "",
                hidden: showForTypes([PropertyType.TEXT]),
              },

              // button properties
              {
                propertyName: "typeSpecificProps.buttonLabel",
                label: "Button Label",
                controlType: "INPUT_TEXT",
                isBindProperty: true,
                isTriggerProperty: false,
                defaultValue: "Click me",
                hidden: showForTypes([PropertyType.BUTTON]),
              },
              {
                propertyName: "typeSpecificProps.isDisabled",
                label: "Disabled",
                helpText: "Disables user interaction with this component",
                controlType: "SWITCH",
                isJSConvertible: true,
                isBindProperty: true,
                isTriggerProperty: false,
                validation: VALIDATION_TYPES.BOOLEAN,
                hidden: showForTypes([PropertyType.BUTTON]),
              },

              // number and currency properties
              {
                propertyName: "typeSpecificProps.currency",
                helpText: "The three letter ISO 4217 currency code",
                label: "Currency code",
                controlType: "DROP_DOWN",
                defaultValue: "USD",
                options: CurrencyList.map((currency) => ({
                  label: currency,
                  value: currency,
                })),
                hidden: showForTypes([PropertyType.CURRENCY]),
                isBindProperty: true,
                isJSConvertible: true,
                isTriggerProperty: false,
              },
              {
                propertyName: "typeSpecificProps.notation",
                helpText: "The display format of the number",
                label: "Number format",
                controlType: "DROP_DOWN",
                defaultValue: "standard",
                options: NUMBER_FORMATTING_OPTIONS,
                hidden: showForTypes([
                  PropertyType.NUMBER,
                  PropertyType.CURRENCY,
                ]),
                isBindProperty: false,
                isTriggerProperty: false,
                propertyCategory: PropsPanelCategory.Appearance,
              },
              minimumFractionDigitsProperty({
                propertyName: "typeSpecificProps.minimumFractionDigits",
                hidden: showForTypes([
                  PropertyType.NUMBER,
                  PropertyType.CURRENCY,
                  PropertyType.PERCENTAGE,
                ]),
                isBindProperty: false,
              }),
              maximumFractionDigitsProperty({
                propertyName: "typeSpecificProps.maximumFractionDigits",
                hidden: showForTypes([
                  PropertyType.NUMBER,
                  PropertyType.CURRENCY,
                  PropertyType.PERCENTAGE,
                ]),
                isBindProperty: false,
              }),

              // link properties
              {
                propertyName: "typeSpecificProps.linkUrl",
                label: "Link URL",
                controlType: "INPUT_TEXT",
                isBindProperty: true,
                isTriggerProperty: false,
                hidden: showForTypes([PropertyType.LINK]),
              },
              {
                propertyName: "typeSpecificProps.linkLabel",
                label: "Link label",
                controlType: "INPUT_TEXT",
                isBindProperty: true,
                isTriggerProperty: false,
                hidden: showForTypes([PropertyType.LINK]),
              },
              {
                propertyName: "typeSpecificProps.openInNewTab",
                label: "Open in a new tab",
                controlType: "SWITCH",
                defaultValue: true,
                isBindProperty: true,
                isTriggerProperty: false,
                hidden: showForTypes([PropertyType.LINK]),
              },

              // image properties
              {
                propertyName: "typeSpecificProps.openImageUrl",
                label: "Open URL on click",
                controlType: "SWITCH",
                helpText:
                  "Opens the image in a new tab when the image is clicked",
                defaultValue: true,
                isBindProperty: true,
                isTriggerProperty: false,
                isJSConvertible: true,
                hidden: showForTypes([PropertyType.IMAGE]),
              },

              // date properties
              {
                propertyName: "typeSpecificProps.dateInputFormat",
                helpText: "Sets the format of the selected date",
                label: "Value format",
                controlType: "DROP_DOWN",
                options: DATE_INPUT_FORMATS,
                isJSConvertible: true,
                hidden: showForTypes([PropertyType.DATE]),
                isBindProperty: true,
                isTriggerProperty: false,
              },
              {
                propertyName: "typeSpecificProps.dateOutputFormat",
                helpText: "Sets the format of the date displayed in the UI",
                label: "Display format",
                controlType: "DROP_DOWN",
                isJSConvertible: true,
                options: DATE_OUTPUT_FORMATS,
                hidden: showForTypes([PropertyType.DATE]),
                isBindProperty: true,
                isTriggerProperty: false,
              },
              {
                helpText:
                  "Allows you to control the timezone of the original dates and displayed dates",
                propertyName: "typeSpecificProps.manageTimezone",
                label: "Manage timezone",
                controlType: "SWITCH",
                isJSConvertible: false,
                isBindProperty: true,
                isTriggerProperty: false,
                hidden: showForTypes([PropertyType.DATE]),
              },
              {
                helpText: "Timezone of the original date",
                propertyName: "typeSpecificProps.timezone",
                label: "Value timezone",
                controlType: "DROP_DOWN",
                isJSConvertible: false,
                options: TIMEZONE_OPTIONS,
                isBindProperty: true,
                isTriggerProperty: false,
                hidden: (props: KeyValueWidgetProps, propertyPath: string) => {
                  if (!propertyPath) {
                    return false;
                  }
                  const baseProperty = getParentPath(propertyPath);
                  const manageTimezone = get(
                    props,
                    `${
                      baseProperty
                        ? `${baseProperty}.manageTimezone`
                        : "manageTimezone"
                    }`,
                    "",
                  );
                  return (
                    showForTypes([PropertyType.DATE])(props, propertyPath) ||
                    !manageTimezone
                  );
                },
              },
              {
                helpText: "Timezone of the displayed date",
                propertyName: "typeSpecificProps.displayTimezone",
                label: "Display timezone",
                controlType: "DROP_DOWN",
                isJSConvertible: false,
                options: TIMEZONE_OPTIONS,
                isBindProperty: true,
                isTriggerProperty: false,
                hidden: (props: KeyValueWidgetProps, propertyPath: string) => {
                  if (!propertyPath) {
                    return false;
                  }
                  const baseProperty = getParentPath(propertyPath);
                  const manageTimezone = get(
                    props,
                    `${
                      baseProperty
                        ? `${baseProperty}.manageTimezone`
                        : "manageTimezone"
                    }`,
                    "",
                  );
                  return (
                    showForTypes([PropertyType.DATE])(props, propertyPath) ||
                    !manageTimezone
                  );
                },
              },
              // boolean properties
              {
                propertyName: "typeSpecificProps.booleanStyleFalse",
                label: "False value",
                controlType: "RADIO_BUTTON",
                options: [
                  {
                    name: "",
                    value: BooleanStyleFalse.EMPTY,
                  },
                  {
                    name: "Close",
                    isIcon: true,
                    icon: "CLOSE",
                    value: BooleanStyleFalse.CLOSE,
                  },
                  {
                    name: "Minus",
                    isIcon: true,
                    icon: "MINUS",
                    value: BooleanStyleFalse.MINUS,
                  },
                  {
                    name: "Empty checkbox",
                    isIcon: true,
                    icon: "EMPTY_CHECKBOX",
                    value: BooleanStyleFalse.EMPTY_CHECKBOX,
                  },
                ],
                defaultValue: BooleanStyleFalse.EMPTY,
                isJSConvertible: false,
                customJSControl: "COMPUTE_TABLE_VALUE",
                isBindProperty: true,
                isTriggerProperty: false,
                hidden: showForTypes([PropertyType.BOOLEAN]),
              },
              {
                helpText: "Whether the key-value pair is visible",
                propertyName: "isVisible",
                isJSConvertible: true,
                label: "Visible",
                controlType: "SWITCH",
                isBindProperty: true,
                isTriggerProperty: false,
              },
            ],
          },
          {
            sectionName: "Presentation",
            showHeader: true,
            headerType: "Collapse",
            sectionCategory: PropsPanelCategory.Appearance,
            children: [
              iconProperty({
                propertyName: "keyProps.icon",
                label: "Label icon",
                helpText:
                  "Select an icon to be displayed alongside the property key",
                isBindProperty: false,
              }),
              iconPositionProperty({
                propertyName: "keyProps.iconPosition",
                helpText: "Select the position of the label icon",
                label: "Header icon position",
                hidden: (props: KeyValueWidgetProps, propertyPath: string) => {
                  const baseProperty = getParentPath(propertyPath);
                  const icon = get(props, `${baseProperty}.icon`, "");
                  return !icon;
                },
              }),
              iconProperty({
                propertyName: "valueProps.icon",
                label: "Value icon",
                helpText:
                  "Select an icon to be displayed alongside the value of the property",
                isBindProperty: false,
                hidden: hideForTypes([PropertyType.BOOLEAN]),
              }),
              iconPositionProperty({
                propertyName: "valueProps.iconPosition",
                helpText: "Select the position of the value icon",
                label: "Value icon position",
                hidden: (props: KeyValueWidgetProps, propertyPath: string) => {
                  const baseProperty = getParentPath(propertyPath);
                  const icon = get(props, `${baseProperty}.icon`, "");
                  return (
                    !icon ||
                    hideForTypes([PropertyType.BOOLEAN])(props, propertyPath)
                  );
                },
              }),
            ],
          },
          // Image Styles
          {
            sectionName: "Styles",
            sectionCategory: PropsPanelCategory.Appearance,
            key: "imageStyles",
            showHeader: true,
            headerType: "Collapse",
            hidden: showForTypes([PropertyType.IMAGE]),
            children: [
              {
                propertyName: "typeSpecificProps.imageSize",
                label: "Image sizing",
                controlType: "DROP_DOWN",
                helpText: "Controls the sizing behavior of the image",
                options: [
                  {
                    label: "Fixed",
                    value: ImageSize.Fixed,
                    subText: "Image is cropped to a fixed size",
                  },
                  {
                    label: "Fit",
                    value: ImageSize.Fit,
                    subText:
                      "Image will fill as much space as possible while maintaining the aspect ratio",
                  },
                  {
                    label: "Cover",
                    value: ImageSize.Cover,
                    subText:
                      "Image covers the entire space and is clipped horizontally or vertically as needed",
                  },
                  {
                    label: "Grow vertically",
                    value: ImageSize.Grow,
                    subText:
                      "Image fills the width of the property. If necessary, the row height will grow to maintain the aspect ratio",
                  },
                ],
                isBindProperty: true,
                isJSConvertible: true,
                isTriggerProperty: false,
                defaultValue: ImageSize.Fixed,
              },
              {
                propertyName: "typeSpecificProps.imageBorderRadius",
                label: "Image border radius",
                controlType: "DROP_DOWN",
                helpText: "Controls the border radius of the image",
                options: [
                  {
                    label: "0px",
                    value: Dimension.px(0),
                  },
                  {
                    label: "4px",
                    value: Dimension.px(4),
                  },
                  {
                    label: "50% (circular)",
                    value: { mode: "%", value: 50 },
                  },
                ],
                isBindProperty: true,
                isJSConvertible: true,
                isTriggerProperty: false,
                defaultValue: { mode: "%", value: 50 },
                optionValueToKey: (value: Dimension) => {
                  return `${value?.value}${value?.mode}`;
                },
              },
            ],
          },
          {
            // Button Styles
            sectionName: "Styles",
            sectionCategory: PropsPanelCategory.Appearance,
            showHeader: true,
            headerType: "Collapse",
            hidden: showForTypes([PropertyType.BUTTON]),
            children: [
              {
                propertyName: "typeSpecificProps.buttonStyle",
                label: "Button style",
                controlType: "DROP_DOWN",
                helpText: "Changes the style of the button",
                options: BUTTON_STYLE_OPTIONS,
                isJSConvertible: true,
                defaultValue: "PRIMARY_BUTTON",
                isBindProperty: true,
                isTriggerProperty: false,
              },
              {
                propertyName: "typeSpecificProps.buttonBackgroundColor",
                label: "Background",
                controlType: "COLOR_PICKER",
                helpText: "Changes the color of the button",
                isJSConvertible: true,
                themeValue: THEME_STYLE_DEFAULTS.primaryBackgroundColor,
                isBindProperty: true,
                isTriggerProperty: false,
                hidden: (props: KeyValueWidgetProps, propertyPath: string) => {
                  const buttonType = get(
                    props,
                    `${getParentPath(propertyPath)}.buttonStyle`,
                    "",
                  );
                  return buttonType && buttonType !== "PRIMARY_BUTTON";
                },
              },
              {
                propertyName: "typeSpecificProps.buttonLabelColor",
                helpText: "Changes the color of the label",
                label: "Label color",
                controlType: "COLOR_PICKER",
                isJSConvertible: true,
                customJSControl: "COMPUTE_TABLE_VALUE",
                themeValue: THEME_STYLE_DEFAULTS.primaryTextColor,
                isBindProperty: true,
                isTriggerProperty: false,
                hidden: (props: KeyValueWidgetProps, propertyPath: string) => {
                  const buttonType = get(
                    props,
                    `${getParentPath(propertyPath)}.buttonStyle`,
                    "",
                  );
                  return buttonType && buttonType !== "PRIMARY_BUTTON";
                },
              },
              //secondary button styles
              {
                propertyName: "typeSpecificProps.buttonBorderColor",
                label: "Border",
                controlType: "COLOR_PICKER",
                helpText: "Changes the color of the button",
                isJSConvertible: true,
                themeValue: THEME_STYLE_DEFAULTS.secondaryBorderColor,
                isBindProperty: true,
                isTriggerProperty: false,
                hidden: (props: KeyValueWidgetProps, propertyPath: string) => {
                  const buttonType = get(
                    props,
                    `${getParentPath(propertyPath)}.buttonStyle`,
                    "",
                  );
                  return buttonType !== "SECONDARY_BUTTON";
                },
              },
              {
                propertyName: "typeSpecificProps.buttonLabelColor",
                helpText: "Changes the color of the label",
                label: "Label color",
                controlType: "COLOR_PICKER",
                isJSConvertible: true,
                themeValue: THEME_STYLE_DEFAULTS.secondaryTextColor,
                isBindProperty: true,
                isTriggerProperty: false,
                hidden: (props: KeyValueWidgetProps, propertyPath: string) => {
                  const buttonType = get(
                    props,
                    `${getParentPath(propertyPath)}.buttonStyle`,
                    "",
                  );
                  return buttonType !== "SECONDARY_BUTTON";
                },
              },
              // tertiary button styles
              {
                propertyName: "typeSpecificProps.buttonLabelColor",
                helpText: "Changes the color of the label",
                label: "Label color",
                controlType: "COLOR_PICKER",
                isJSConvertible: true,
                themeValue: THEME_STYLE_DEFAULTS.tertiaryTextColor,
                isBindProperty: true,
                isTriggerProperty: false,
                hidden: (props: KeyValueWidgetProps, propertyPath: string) => {
                  const buttonType = get(
                    props,
                    `${getParentPath(propertyPath)}.buttonStyle`,
                    "",
                  );
                  return buttonType !== "TERTIARY_BUTTON";
                },
              },
            ],
          },
          {
            sectionName: "Tags styles",
            sectionCategory: PropsPanelCategory.Appearance,
            children: [
              // general styles section is hidden for tags, we want to let
              {
                propertyName: "typeSpecificProps.textWrap",
                label: "Wrap tags",
                controlType: "SWITCH",
                isJSConvertible: true,
                isBindProperty: true,
                isTriggerProperty: false,
                validation: VALIDATION_TYPES.BOOLEAN,
                helpText: "If enabled, the tags will wrap within the cell",
              },
              {
                propertyName: "typeSpecificProps.tagDisplayConfig",
                label: "Custom tag colors",
                controlType: "CUSTOM_TAG_COLORS",
                headerControlType: "ADD_TAG_COLOR",
                customJSControl: "INPUT_JS_EXPR",
                canExpandEditor: true,
                isJSConvertible: true,
                isBindProperty: true,
                isTriggerProperty: false,
              },
            ],
            hidden: showForTypes([PropertyType.TAGS]),
          },
          {
            sectionName: "Button properties",
            sectionCategory: PropsPanelCategory.EventHandlers,
            hidden: showForTypes([PropertyType.BUTTON]),
            children: [
              getPopoverConfig(
                "typeSpecificProps.onClick",
                "Triggers an action when the button is clicked",
                {
                  customJSControl: "COMPUTE_TABLE_VALUE",
                },
                undefined,
                undefined,
                "onClick",
              ),
            ],
          },
        ],
      },
    },
  ]),
  PanelCategory(PropsPanelCategory.Appearance, [
    labelPositionProperty({
      parentDottedPath: "keyProps",
      defaultValue: WidgetLabelPosition.LEFT,
      hidden: () => false,
    }),
    ...typographyProperties({
      textStyleParentDottedPath: "keyProps",
      propertyNameForHumans: "Label",
      defaultVariant: DEFAULT_KEY_VALUE_WIDGET_TEXT_STYLE_VARIANT,
    }),
    ...typographyProperties({
      textStyleParentDottedPath: "valueProps",
      propertyNameForHumans: "Value",
      defaultVariant: DEFAULT_KEY_VALUE_WIDGET_TEXT_STYLE_VARIANT,
    }),
    ...styleProperties({
      propertyNamespaceDottedPath: "styleProps",
      defaultBorderProperty: DEFAULT_BORDER_OBJECT,
      borderThemeValue: DEFAULT_BORDER_OBJECT,
      backgroundColorDefaultValue: "{{ theme.colors.neutral }}",
      backgroundColorThemeValue: "{{ theme.colors.neutral }}",
      borderRadiusThemeValue: ({ theme }: { theme: any }) => {
        return {
          treatAsNull: false,
          value: createPerCornerBorderRadius(theme.borderRadius),
        };
      },
    }),
    {
      propertyName: "styleProps.divider",
      label: "Divider",
      helpText: "Display dividers between key value pairs",
      controlType: "COLORED_DIMENSION_CONTROL",
      isJSConvertible: false,
      isBindProperty: true,
      isTriggerProperty: false,
      isRemovable: true,
      visibility: "SHOW_NAME",
      themeValue: ({ theme }: { theme: GeneratedTheme }) => {
        return {
          value: theme.keyValue.divider,
          treatAsNull: true,
        };
      },
    },
  ]),
  PanelCategory(PropsPanelCategory.Layout, [
    paddingProperty({ propertyName: "styleProps.padding" }),
    {
      propertyName: "styleProps.spacing",
      label: "Spacing",
      isRemovable: false,
      controlType: "DIMENSION_VALUE_CONTROL",
      dimensionUnit: "px",
      isBindProperty: false,
      isTriggerProperty: false,
      helpText: "Spacing between each key-value pair",
      themeValue: ({ theme }: { theme: GeneratedTheme }) => {
        return {
          value: theme.keyValue.spacing,
          treatAsNull: true,
        };
      },
    },
    sizeSection({ heightSupportsFitContent: true }),
    ...visibleProperties({ useJsExpr: false }),
  ]),
  PanelCategory(PropsPanelCategory.Interaction, []),
  PanelCategory(PropsPanelCategory.EventHandlers, []),
];

export default config;
