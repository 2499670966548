import {
  ColoredDimensionStyle,
  Dimension,
  MultiStepDef,
  Padding,
  PerCornerBorderRadius,
  PerSideBorder,
} from "@superblocksteam/shared";
import { WidgetLabelPosition } from "legacy/constants/WidgetConstants";
import { TextStyleWithVariant } from "legacy/themes";
import { RunWidgetEventHandlers, WidgetPropsRuntime } from "../BaseWidget";
import { ButtonStyle } from "../Shared/Button/types";
import { BooleanStyleFalse, ImageSize } from "../Shared/ValueWithTypeUtils";
import { TagDisplayConfig } from "../TableWidget/TableComponent/Constants";
import { WithMeta } from "../withMeta";

type BaseProperty = {
  id: string;
  key: string;
  label: string;
  type: string;
  displayedValue?: unknown;
  isVisible: boolean;
  keyProps?: {
    icon?: string;
    iconPosition?: "LEFT" | "RIGHT";
  };
  valueProps?: {
    icon?: string;
    iconPosition?: "LEFT" | "RIGHT";
  };
  typeSpecificProps?: {
    // button properties
    buttonLabel?: string;
    isDisabled?: boolean;
    buttonStyle?: ButtonStyle;
    buttonLabelColor?: string;
    onClick?: MultiStepDef;
    buttonBorderColor?: string;
    buttonBackgroundColor?: string;

    // currency properties
    currency?: string;

    // number properties
    notation?: Intl.NumberFormatOptions["notation"] | "unformatted";
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;

    // link properties
    linkUrl?: string;
    linkLabel?: string;
    openInNewTab?: boolean;

    // image properties
    openImageUrl?: boolean;
    imageSize?: ImageSize;
    imageBorderRadius?: Dimension;

    // date properties
    dateInputFormat?: string;
    dateOutputFormat?: string;
    manageTimezone?: boolean;
    timezone?: string;
    displayTimezone?: string;

    // tags properties
    textWrap?: boolean;
    tagDisplayConfig?: Record<string, TagDisplayConfig>;

    // boolean properties
    booleanStyleFalse?: BooleanStyleFalse;
  };
};

type DerivedProperty = BaseProperty & {
  computedValue: unknown;
  isDerived: true;
};

type PrimaryProperty = BaseProperty & {
  isDerived: false;
};

export enum PropertyType {
  TEXT = "text",
  NUMBER = "number",
  CURRENCY = "currency",
  PERCENTAGE = "percentage",
  IMAGE = "image",
  VIDEO = "video",
  DATE = "date",
  BUTTON = "button",
  LINK = "link",
  BOOLEAN = "boolean",
  TAGS = "tags",
  EMAIL = "email",
}

export type KeyValueProperty = PrimaryProperty | DerivedProperty;

interface StyleProps {
  border?: PerSideBorder;
  borderRadius?: PerCornerBorderRadius;
  backgroundColor?: string;
  padding?: Padding;
  spacing: Dimension;
  divider?: ColoredDimensionStyle;
}

export interface KeyValueWidgetProps extends WidgetPropsRuntime, WithMeta {
  keyValueData: Record<string, unknown>;
  properties: Record<string, KeyValueProperty>;
  propertiesOrder: string[];
  isVisible?: boolean;
  styleProps: StyleProps;
  keyProps: {
    textStyle: TextStyleWithVariant;
    position: WidgetLabelPosition;
  };
  valueProps: {
    textStyle: TextStyleWithVariant;
  };
  runEventHandlers: (payload: RunWidgetEventHandlers) => void;
}

export type KeyValueComponentProps = Pick<
  KeyValueWidgetProps,
  | "keyValueData"
  | "styleProps"
  | "properties"
  | "propertiesOrder"
  | "keyProps"
  | "valueProps"
  | "runEventHandlers"
>;
